@import '_helpers';

body {
    font-family: 'PT Sans', Arial, Helvetica, sans-serif;
    margin: 0px;
    padding: 0px;
    font-size: 13px;
    color: #4f4857;
}

input {
    font-family: 'PT Sans', Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #4f4857;
}

select {
    font-family: 'PT Sans', Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #4f4857;
}

textarea {
    font-family: 'PT Sans', Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #4f4857;
}

a {
    text-decoration: none
}

h1 {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: normal;
}

h2 {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: normal;
}

p {
    margin-top: 0px;
    margin-bottom: 0px;
}

input.invalid {
    border-color: red;
    outline-color: red;
}

/* pop-ups */
div.loader2 > div.bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

div.loader2 > div.spinner {
    position: fixed;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    display: inherit;
}

div.loader2 {
    text-align: center;
}

div.loader2 > div.bg {
    z-index: 10001;
}

div.loader2 > div.spinner {
    margin-top: 200px;
    z-index: 10002;
    opacity: 1;
    width: 120px;
    height: 120px;
    padding: 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

div.loader2.lottie > div.spinner {
    .lottie-container {
        width: 72px;
        height: 72px;
        margin: 0 auto 0 auto;

        &.hidden {
            display: none;
        }
    }
}

div.loader2 {
    &.hidden {
        display: none;
    }
}

/* Header styles */
#header {
    height: 80px;
    width: 100%;
    background-repeat: repeat-x;
}

#logo {
    display: inline;
    float: left;
}

#headercontent {
    width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 60px;
}

/* HeaderV2 styles */
#headerV2 {
    width: 100%;
    height: 56px;
    margin-bottom: 15px;

    .header-container {
        width: 1140px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 60px;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .externalheadernologinV2 {
            text-align: right;
            display: flex;
            flex-direction: column;

            a, span {
                color: white;
            }

            .loginlinkexternal {
                margin-top: 5px;
            }
        }
    }
}

/* Main content styles */
#maincontent {
    width: 1140px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
    display: block;
    clear: both;
    padding: 0 60px;
}

#columnleftexternal {
    width: 820px;
    display: inline;
    float: left;
    margin-top: 12px;
}

#columnrightexternal {
    width: 300px;
    margin-left: 20px;
    display: inline;
    float: left;
    margin-top: 12px;
}

.clearfix {
    display: block;
    clear: both;
    font-size: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
}
/* Footer  styles */
#footer {
    height: 75px;
    width: 100%;
    display: block;
    clear: both;
    margin-top: 30px;
}

#footercontent {
    width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 60px;
}

#footercontent p {
    color: #645e6b
}

#footercontentleft p {
    padding-top: 18px
}

#externallinks {
    width: 1140px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

#footercontentleft {
    display: inline;
    float: left;
}

#footercontentright {
    display: inline;
    float: right;
}

#externaltwitter {
    display: inline;
    float: right;
    margin-top: 17px;
    text-align: center;
    margin-right: 15px;
}

#externalfacebook {
    display: inline;
    float: right;
    margin-top: 15px;
    text-align: center;
}

#externalheaderlogin {
    width: 456px;
    height: 71px;
    display: inline;
    float: right;
}

.loginlinkexternal {
    font-size: 13px;
    font-weight: bold;
}

.externalloginspace {
    margin-top: 20px !important
}

#externalheadernologin {
    width: 456px;
    height: 71px;
    display: inline;
    float: right;
}

#externalheadernologin p {
    width: 456px;
    display: inline;
    float: right;
    text-align: right;
    color: #FFFFFF;
    margin-top: 8px;
}

#externalheadernologin a {
    color: #FFFFFF
}

#externalheaderlogin p {
    display: inline;
    float: right;
    font-size: 12px;
}

#externalheaderloginrow1 input {
    display: inline;
    float: left;
    width: 122px;
    margin-right: 8px;
}

#externalheaderlogin img {
    display: inline;
    float: left;
    margin-left: 5px;
    margin-top: 1px;
}

#externalheaderloginrow1 {
    display: block;
    clear: both;
    color: #FFFFFF;
    height: 24px;
    margin-top: 15px;
}

#externalheaderloginrow1 p {
    margin-right: 8px;
    margin-top: 3px;
}

#externalheaderloginrow2 {
    display: block;
    clear: both;
    color: #FFFFFF;
    height: 24px;
    margin-top: 5px;
}

#externalheaderloginrow2 input {
    display: inline;
    float: left;
    margin-top: 4px;
    margin-left: 94px;
    width: 12px;
    height: 12px;
    padding: 0px;
    margin-right: 5px;
}

#externalheaderloginrow2 p {
    margin-right: 8px;
    margin-top: 3px;
}

#externalforgotpassword {
    margin-left: 58px
}
/* Button  styles */
a.button_style {
    font-size: 18px;
    font-weight: bold;
    color: #FFF;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    text-shadow: 1px 1px 1px #333333;
}

a.button_style:hover {
    color: #f1dabf
}

a.button_style:visited {
    color: #FFFFFF
}

a.button_style:active {
    color: #FFFFFF
}

.buttonicon {
    border: none;
    position: relative;
    top: 4px;
}

.favoriteclicked {
    cursor: default !important
}

.button_style_gray {
    background-color: #9ba4a6;
    font-size: 18px;
    font-weight: bold;
    color: #FFF !important;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    text-shadow: 1px 1px 1px #333333;
}

.buttonunavailable {
    font-size: 18px;
    font-weight: bold;
    color: #FFF;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    text-shadow: 1px 1px 1px #333333;
    opacity: 0.3;
    filter: alpha(opacity=30);
    cursor: default !important;
}

.buttonunavailable:hover {
    font-size: 18px;
    font-weight: bold;
    color: #FFF !important;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    text-shadow: 1px 1px 1px #333333;
    opacity: 0.3;
    filter: alpha(opacity=30);
    cursor: default !important;
}

.arrowunavailable {
    opacity: 0.3;
    filter: alpha(opacity=30);
    cursor: default !important;
}
/* Pagination  styles */
#paginationsearch {
    width: 820px;
    display: block;
    clear: both;
    font-weight: bold;
    margin-top: 20px;
}

#paginationsearchleft {
    display: inline;
    float: left;
}

#paginationsearchright {
    display: inline;
    float: right;
}

#paginationsearchcenter {
    display: inline;
    float: left;
    margin-left: 30px;
    font-size: 14px;
    margin-top: 3px;
}

.paginationon {
    padding: 0px 4px 0px 4px;
    color: #FFFFFF;
    text-align: center;
    border-radius: 3px;
    margin-left: 2px;
    margin-right: 2px;
}

#paginationsearchcenter img {
    margin-left: 5px;
    margin-right: 5px;
}

a.button_style_send {
    background-color: #FFF;
    font-size: 12px;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    border: solid 1px #cbc8ce;
}

.h1header {
    font-size: 24px;
    color: #bf2726;
    text-transform: uppercase;
}

html {
    overflow-y: scroll
}

.RightNowContainerExternal {
    margin-top: 20px
}
/* external pages */
.externalwomenbrowsetop {
    border-radius: 3px 3px 3px 3px;
    clear: both;
    display: block;
    padding-bottom: 16px;
    width: 300px;
    text-align: center;
    margin-top: 20px;
}

.externalwomenbrowsetop h1 {
    color: #FFFFFF;
    font-size: 22px;
    font-weight: normal;
    margin-bottom: 9px;
    margin-top: 0;
    padding-top: 9px;
    text-align: center;
}

.externalwomenbrowsebottom {
    border-radius: 0 0 3px 3px;
    clear: both;
    display: block;
    margin-top: -3px;
    padding-bottom: 10px;
    width: 300px;
}

.browserlink {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    display: block;
    clear: both;
    padding-top: 17px;
}

.externalmeetwomen {
    padding-top: 19px
}

.externalmeetwomenleft {
    display: inline;
    float: left;
    width: 130px;
    margin-left: 15px;
}

.externalmeetwomenright {
    display: inline;
    float: left;
    width: 140px;
    margin-left: 2px;
}

.externalmeetwomenright p {
    font-size: 14px;
    color: #FFFFFF;
    line-height: 16px;
}

.externalsearcharticles {
    clear: both;
    display: block;
    margin-top: 25px;
    width: 300px;
}

.externalsearcharticles h1 {
    border: none;
    border-bottom: solid 1px #ECE9EF;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    padding-bottom: 4px;
    padding-left: 0;
    width: 270px;
}

.externalsearcharticles p {
    margin-bottom: 15px;
    margin-left: 0;
}

.externalsearcharticles a {
    color: #4F4857 !important;
}

.externalsearcharticles a:visisted {
    color: #4F4857 !important;
}

.seemorearticles {
    cursor: pointer;
    margin-left: 0px;
    display: block;
}

.seemorearticles img {
    display: inline;
    float: left;
    margin-top: 6px;
    margin-left: 5px;
}

.seemorearticles p {
    display: inline;
    float: left;
    font-size: 14px;
}

.externalsuccessstories {
    clear: both;
    display: block;
    margin-top: 0px;
    width: 300px;
}

.externalsuccessstories h1 {
    border: none;
    border-bottom: solid 1px #ECE9EF;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    padding-bottom: 4px;
    padding-left: 0;
    width: 270px;
}

.datingarticleexpanded {
    display: none
}

.clearfix {
    font-size: 2px
}

.successstoriescaption {
    font-size: 18px;
    font-style: italic;
    margin-top: 10px;
    color: #989898;
}

.moresuccessstories {
    margin-top: 20px;
    font-size: 14px;
}

.datingtipsarea {
    width: 780px;
    border-radius: 3px;
    margin-top: 20px;
    padding: 20px;
    font-size: 14px;
}
/*.datingtipsheadline h1 {
    display: inline;
    float: left;
    font-size: 20px;
}
.datingtipsheadline p {
    display: inline;
    float: left;
    margin-top: 6px;
    margin-left: 20px;
}*/
.datingtipslockright {
    display: inline;
    float: left;
    width: 640px;
    margin-left: 10px;
}

.datingtipslockright p {
    margin-bottom: 10px
}

.datingtipslockleft {
    display: inline;
    float: left;
    width: 100px;
}

.datingtipslock {
    display: block;
    clear: both;
    padding-top: 20px;
}

.datingtipsexpandarea {
    display: block;
    clear: both;
    padding-top: 20px;
    width: 760px;
}

.datingtipsexpandarea h1 {
    display: block;
    text-align: left;
    font-size: 20px;
    margin-bottom: 10px;
}

.datingtipsexpandarea h2 {
    display: block;
    text-align: left;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
}

.datingtipsexpandarea div.text {
    margin-bottom: 10px;
}

.datingtipsexpandarea span.emphasis {
    text-decoration: underline;
}

.datingtipsexpandarea li {
    margin-bottom: 0px
}

.datingtipsexpandarea p {
    margin-bottom: 10px
}

.orangerepeat {
    cursor: pointer;
    display: block;
    clear: both;
}

.orangerepeat p {
    display: inline;
    float: left;
}

.orangerepeat img {
    display: inline;
    float: left;
    margin-top: 5px;
    margin-left: 3px;
}

.fraudextended {
    display: none;
    clear: both;
    padding-bottom: 10px;
}

.fraudcontent {
    display: block;
    clear: both;
    padding-top: 10px;
}

.tipsextended {
    display: none;
    clear: both;
    padding-bottom: 10px;
}

.tipscontent {
    display: block;
    clear: both;
}

.safetyextended {
    display: none;
    clear: both;
    padding-bottom: 10px;
}

.safetycontent {
    display: block;
    clear: both;
}

.meetingextended {
    display: none;
    clear: both;
    padding-bottom: 10px;
}

.meetingcontent {
    display: block;
    clear: both;
}

.fraud {
    display: block;
    clear: both;
    padding-bottom: 0px;
}

.tips {
    display: block;
    clear: both;
    padding-bottom: 0px;
}

.safety {
    display: block;
    clear: both;
    padding-bottom: 0px;
}

.meeting {
    display: block;
    clear: both;
    padding-bottom: 0px;
}
/* Terms and Conditions */
/* Privacy Policy */
/* Success Stories */
.successstoriesli li {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-bottom: 20px;
}

.successstoriesli {
    margin: 0px;
    padding: 0px;
}

.leftquotesuccess {
    margin-right: 10px
}

.rightquotesuccess {
    margin-left: 10px
}
/* Dating Articles */
/* About */
.externalaboutpic {
    display: inline;
    float: left;
    margin-right: 20px;
}

.aboutdatingbottom {
    padding-top: 10px
}

.aboutdatingbottom p {
    display: inline;
    float: right;
}

.meetheadlineabout {
    font-size: 20px;
    margin-right: 20px;
}
/* Site Map */
.externalsitemap {
    width: 1060px;
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 20px 40px 20px 40px;
    margin-top: 20px;
}

.externalsitemap h1 {
    font-size: 20px
}

.externalsitemapclear {
    display: block;
    clear: both;
    margin-top: 20px;
}

.externalsitemapcol1 {
    display: inline;
    float: left;
    width: 235px;
    border: none;
    border-right: solid 1px #cfd6df;
    padding-right: 40px;
}

.externalsitemapcol2 {
    display: inline;
    float: left;
    width: 195px;
    border: none;
    border-right: solid 1px #cfd6df;
    padding-left: 40px;
    padding-right: 40px;
}

.externalsitemapcol3 {
    display: inline;
    float: left;
    width: 150px;
    width: 250px;
    padding-left: 40px;
}

.externalsitemapcol4 {
    display: inline;
    float: left;
    width: 215px;
    font-size: 14px;
}

.sitemap {
    list-style: none;
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    margin-bottom: 20px;
}

.sitemap li {
    margin-bottom: 7px
}

.asterix2 {
    color: #4F4857 !important
}

.sitemapsidelinks {
    border-radius: 3px;
    background-color: #edf3f5;
}

.sitemapline {
    border: none;
    border-bottom: solid 1px #e2e1e3;
}

.sitemapsidelinkstop {
    padding-top: 10px;
    margin-left: 20px;
}

.sitemapsidelinkstop p {
    margin-bottom: 5px
}

.sitemapline {
    font-size: 7px
}

.asteriskinfo {
    font-size: 12px;
    margin-top: 20px;
    margin-left: 20px;
}

.sitemapsidelinksbottom ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-left: 20px;
    margin-top: 15px;
    padding-bottom: 16px;
}

.sitemapsidelinksbottom li {
    margin-bottom: 7px
}

#footercontentright {
    display: none
}

#columnright {
    margin-top: 10px
}
/* unsubscribe email */
.unsubscribeemail {
    width: 820px;
    border-radius: 3px;
    margin-top: 20px;
    padding-bottom: 28px;
}

.unsubscribeemail h1 {
    font-size: 20px;
    padding-top: 20px;
    margin-left: 20px;
}

.unsubscribeemail p {
    margin-left: 20px;
    margin-top: 18px;
    font-size: 14px;
    margin-right: 30px;
}

.unsubscribeformarea {
    display: block;
    width: 820px;
    margin-top: 10px;
    clear: both;
}

.unsubscribeformarea p {
    display: inline;
    float: left;
}

.unsubscribeformarea input {
    display: inline;
    float: left;
    margin-top: 14px;
    width: 221px;
    height: 25px;
    font-size: 15px;
    margin-left: 20px;
}

#externalloginerrorpassword {
    background-color: #BF2726;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    height: 25px;
    margin-top: 20px;
    width: 390px;
}

#externalloginerrorpassword p {
    margin-left: 40px;
    padding-top: 3px;
}

#externalloginerrorpasswordinvalid {
    background-color: #BF2726;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    height: 64px;
    margin-top: 20px;
    width: 390px;
}

#externalloginerrorpasswordinvalid p {
    margin-left: 40px;
    padding-top: 3px;
    margin-right: 20px;
}

#externalloginerrorarrowinvalid img {
    margin-left: 70px
}

.clearfix {
    font-size: 6px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.unsubscribeseepeople {
    width: 700px;
    display: block;
    clear: both;
    margin-top: 25px;
}

.unsubscribeseepeople h1 {
    font-size: 20px;
    color: #bf2726;
    margin-bottom: 15px;
}

.unsubscribeseepeoplerepeat {
    width: 132px;
    text-align: center;
    display: inline;
    float: left;
    margin-right: 30px;
}

.unsubscribeseepeoplerepeat img {
    margin-bottom: 5px;
    width: 132px;
    height: 165px;
}

.unsubscribeseepeoplerepeatbtn {
    text-align: center;
    display: inline;
    float: left;
    margin-right: 30px;
}

.unsubscribeseepeoplerepeatbtn {
    padding-top: 137px
}

.usernameemailedit {
    margin-left: 49px !important
}

#errorhide {
    display: block;
    clear: both;
    &.hidden {
        display: none;
    }
}
/* externalsearch */
#externalsearchmiddletop h1 {
    font-size: 20px;
    font-weight: normal;
}

#externalsearchmiddletop p {
    font-size: 14px;
    margin-top: 16px;
}

#searchmenwomen {
    text-align: center;
    font-weight: bold;
    font-size: 13px !important;
    padding-top: 10px;
}

#searchpeoplerows {
    width: 686px;
    margin-left: 65px;
    margin-top: 0px;
}

.searchpersonrepeat {
    width: 300px;
    height: 343px;
    background-color: #FFFFFF;
    display: inline;
    float: left;
    border-radius: 2px;
    margin-left: 30px;
    margin-top: 20px;
}

.cropsearchpic {
    display: inline;
    float: left;
    width: 117px;
    height: 147px;
}

.searchpersonpic p {
    display: inline;
    float: left;
    margin-left: 20px;
    line-height: 19px;
    margin-top: 4px;
}

.searchpersonrepeat h2 {
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.searchpersonpic {
    margin-top: 10px;
    margin-left: 20px;
}

.searchpersonbtns {
    display: block;
    clear: both;
    padding-top: 14px;
    margin-left: 20px;
}

.searchpersonbtns img {
    margin-right: 15px
}

.searchpersoninfo {
    display: block;
    clear: both;
    margin-left: 20px;
    width: 257px;
    margin-top: 18px;
}

.searchpersoninfo p {
    line-height: 16px
}

.searchpersoninfo label.question-label {
    font-weight: bold;
}

#externalpaginationsearch {
    width: 820px;
    display: block;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
    margin-top: 15px;
    font-weight: bold;
}

#externalpaginationsearchleft {
    display: inline;
    float: left;
}

#externalpaginationsearchright {
    display: inline;
    float: right;
}

#externalpaginationsearchcenter {
    display: inline;
    float: left;
    margin-left: 540px;
    font-size: 14px;
    margin-top: 7px;
}

#externalpaginationsearchcenter img {
    margin-left: 5px;
    margin-right: 5px;
}

#externalsearchtopcities {
    width: 300px
}

#externalsearchstates {
    width: 300px;
    height: 600px;
    background-image: legacy-image-url("external/statesbg.gif");
    display: block;
    clear: both;
}

#externalsearcharticles {
    width: 300px;
    margin-top: 25px;
    display: block;
    clear: both;
}

#externalsearchtopcities h1 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 10px;
}

#externalsearchtopcities p {
    margin-bottom: 20px;
    line-height: 19px;
}

#externalsearchstates h1 {
    color: #FFFFFF;
    font-weight: bold;
    margin-top: 0px;
    padding-top: 9px;
    margin-bottom: 9px;
    text-align: center;
    font-size: 18px;
}

#externalsearchstates img {
    margin-left: 14px
}

#externalsearchstates p {
    width: 100px;
    display: inline;
    float: left;
    margin-left: 30px;
    margin-top: 30px;
    font-size: 14px;
}

#externalsearchstates a {
    color: #FFF
}

#externalsearcharticles h1 {
    font-weight: bold;
    font-size: 18px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 1px #ece9ef;
    padding-bottom: 4px;
    margin-bottom: 16px;
    width: 270px;
    padding-left: 0px;
}

#externalsearcharticles p {
    margin-bottom: 15px;
    margin-left: 0px;
}

.spacer {
    height: 30px
}

#externalsearchbottombutton {
    width: 370px;
    height: 31px;
    border-radius: 3px;
    margin-left: 280px;
    margin-bottom: 30px;
    margin-top: 20px;
}

#externalsearchbottombutton a {
    font-weight: bold
}

#externalsearchbottombutton p {
    text-align: center;
    padding-top: 7px;
}

#profileoverlayplace2 {
    position: relative;
    z-index: 54001;
}

#profileoverlay2 {
    position: absolute;
    top: 122px;
    z-index: 50;
    width: 117px;
    height: 25px;
}

#profileoverlay2 a {
    color: #FFFFFF;
}

#profileoverlay2 p {
    margin-top: 2px;
    margin-left: 4px;
    display: inline;
    float: left;
    font-size: 13px;
}

#profileoverlay2 img {
    margin-top: 1px;
    margin-left: 3px;
    display: inline;
    float: left;
}

.nostates {
    height: 95px !important;
    border-radius: 3px;
    overflow: hidden;
}

#profilemessageideasarea p {
    margin-bottom: 20px;
    width: 425px;
}

.boldmessages {
    font-weight: bold
}

.sendmessagelink p {
    display: inline;
    float: left;
    margin-left: 10px;
}

.sendmessagelink a {
    display: inline;
    float: left;
}

#profilemessageideasarea {
    border-top: solid 1px #ECE9EF;
    border-left: none;
    border-right: none;
    border-bottom: none;
    width: 498px;
    padding-top: 30px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

#profilemessageideasarea h2 {
    margin-bottom: 20px
}

.externalstatesearchtop {
    width: 300px;
    display: block;
    clear: both;
    border-radius: 3px 3px 3px 3px;
    padding-bottom: 16px;
    margin-top: 20px;
}

.externalstatesearchbottom {
    width: 300px;
    display: block;
    clear: both;
    border-radius: 0px 0px 3px 3px;
    padding-bottom: 10px;
    margin-top: -3px;
}

.externalstatesearchtop h1 {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 9px;
    margin-top: 0;
    padding-top: 9px;
    text-align: center;
}

.externalstatesearchtop img {
    margin-left: 14px
}

.externalstatesearchbottom p {
    display: inline;
    float: left;
    font-size: 14px;
    margin-left: 30px;
    margin-top: 30px;
    width: 100px;
}

.externalstatesearchbottom a {
    color: #FFF
}

.externalstatesearchbottom a:visited {
    color: #FFF
}

.externalsignupnowbanner {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: normal;
}
/* forgot password*/
.externalcontainer {
    width: 820px;
    border-radius: 3px;
}

#externalloginmiddleleft {
    width: 440px;
    display: inline;
    float: left;
    margin-top: 16px;
}

#externalloginmiddleright {
    width: 318px;
    display: inline;
    float: right;
    margin-top: 67px;
    font-size: 14px;
    margin-bottom: 30px;
}

#externalloginmiddleleft h1 {
    font-size: 24px;
    margin-left: 40px;
}

#externalloginmiddleright p {
    margin-left: 32px;
    padding-top: 25px;
    padding-bottom: 25px;
    width: 286px;
    border-top: none;
    border-left: none;
    border-right: none;
}

#externalloginmiddleright p:first-child {
    border-bottom: solid 1px #e2e1e3;
}

#externalloginemail {
    width: 310px;
    margin-left: 50px;
    margin-top: 20px;
    display: block;
    clear: both;
    height: 42px;
}

#externalloginemail input {
    display: inline;
    float: right;
    margin-left: 10px;
    width: 200px;
    font-size: 15px;
}

#externalloginemail p {
    display: inline;
    float: left;
    margin-top: 3px;
}

#externalloginsecurityfpo {
    display: block;
    clear: both;
    height: 30px;
    margin-left: 153px;
}

#externalloginsecurity {
    display: block;
    clear: both;
    height: 40px;
    margin-left: 50px;
    width: 310px;
    margin-top: 20px;
}

#externalloginsecuritytext {
    display: block;
    clear: both;
    height: 5px;
    margin-left: 50px;
    width: 370px;
    margin-top: 10px;
}

#externalloginsecurity input {
    display: inline;
    float: right;
    margin-left: 10px;
    width: 200px;
    font-size: 15px;
}

#externalloginsecurity p, #externalloginsecuritytext p {
    display: inline;
    float: left;
    margin-top: 3px;
}

#externalloginbtn {
    display: block;
    clear: both;
    margin-left: 100px;
    margin-top: 10px;
    margin-left: 124px;
    height: 50px;
}

#externalforgotpasswordbtn {
    display: block;
    clear: both;
    margin-left: 100px;
    margin-top: 10px;
    margin-left: 154px;
    height: 50px;
}

#externalloginerror {
    font-size: 14px;
    font-weight: bold;
    color: #FFF;
    width: 400px;
    height: 25px;
    background-color: #bf2726;
    margin-top: 20px;
}

.field-validation-error.hidden {
    display: none;
}

#externalloginerror.hidden {
    display: none;
}

#externalloginerror2 {
    font-size: 14px;
    font-weight: bold;
    color: #FFF;
    width: 390px;
    height: 25px;
    background-color: #bf2726;
    margin-top: 20px;
}

#externalloginerror p {
    text-align: center;
    padding-top: 3px;
}

#externalloginerror2 p {
    text-align: center;
    padding-top: 3px;
}

#externalloginerrorarrow img {
    margin-left: 80px
}

.external-login-arrow img {
    margin: 4px 0 0 192px;
    position: absolute;
}

#externalloginerrorarrow2 img {
    margin-left: 80px
}

#externallogoutpage #externalloginerrorarrow img,
#externalloginmiddleleft #externalloginerrorarrow img {
    margin-left: 192px;
    position: absolute;
}

#externalforgottext {
    width: 320px;
    margin-left: 40px;
    margin-top: 30px;
    margin-bottom: 100px;
}

#externalforgottext p {
    margin-bottom: 15px
}

#changepasswordtext {
    margin-top: 20px;
    margin-left: 40px;
}

#externalloginpassword1 {
    margin-top: 20px;
    margin-left: 40px;
    display: block;
    clear: both;
    height: 32px;
}

#externalloginpassword1 p {
    display: inline;
    float: left;
}

#externalloginpassword1 input {
    display: inline;
    float: left;
    width: 200px;
    font-size: 15px;
    margin-left: 39px;
}

#externalloginpassword2 {
    padding-top: 20px;
    margin-left: 40px;
    display: block;
    clear: both;
    height: 40px;
}

#externalloginpassword2 p {
    display: inline;
    float: left;
}

#externalloginpassword2 input {
    display: inline;
    float: left;
    width: 200px;
    font-size: 15px;
    margin-left: 20px;
}

#externalloginbtnpassword {
    display: block;
    clear: both;
    margin-top: 50px;
    margin-left: 189px;
    height: 50px;
}

#passwordstrength1 {
    width: 200px;
    margin-left: 180px;
    display: block;
    clear: both;
}

#passwordstrength1 p {
    text-align: center;
    margin-bottom: 4px;
}

#passwordstrength1 h2 {
    text-align: center;
    margin-bottom: 2px;
}

#passwordstrength2 {
    width: 200px;
    margin-left: 180px;
    display: block;
    clear: both;
}

#passwordstrength2 p {
    text-align: center;
    margin-bottom: 4px;
    background-color: #FFFF00;
}

#passwordstrength2 h2 {
    text-align: center;
    margin-bottom: 2px;
}

#passwordstrength3 {
    width: 200px;
    margin-left: 180px;
    display: block;
    clear: both;
}

#passwordstrength3 p {
    text-align: center;
    margin-bottom: 4px;
    background-color: #F00;
}

#passwordstrength3 h2 {
    text-align: center;
    margin-bottom: 2px;
}

.good {
    width: 60px;
    height: 20px;
    display: inline;
    float: left;
    background-color: #0F0;
    margin-left: 3px;
    text-align: center;
}

.great {
    width: 60px;
    height: 20px;
    display: inline;
    float: left;
    background-color: #0F0;
    margin-left: 3px;
    text-align: center;
}

.poor {
    width: 60px;
    height: 20px;
    display: inline;
    float: left;
    background-color: #0F0;
    margin-left: 7px;
    text-align: center;
}

.nobg {
    background-color: #CCC !important
}

#passwordstrength4 {
    width: 200px;
    margin-left: 180px;
    display: block;
    clear: both;
}

#passwordstrength4 p {
    text-align: center;
    margin-bottom: 4px;
    background-color: #CCC;
}

#passwordstrength4 h2 {
    text-align: center;
    margin-bottom: 2px;
}

#forgotpasswordlookuptext {
    margin-left: 40px;
    margin-top: 18px;
}

#forgotpasswordcc {
    display: block;
    clear: both;
    margin-left: 40px;
    margin-top: 10px;
    font-size: 14px;
    height: 30px;
}

#forgotpasswordccmonthyear {
    margin-left: 40px;
    margin-top: 10px;
    display: block;
    clear: both;
}

#forgotpasswordsubmittext {
    margin-left: 40px;
    margin-top: 10px;
}

#externalloginbtnpasswordlookup {
    display: block;
    clear: both;
    margin-top: 26px;
    margin-left: 38px;
    height: 50px;
}

#forgotpasswordcc p {
    display: inline;
    float: left;
    margin-right: 5px;
}

#externalloginmiddleleftlookup {
    width: 450px;
    display: inline;
    float: left;
    margin-top: 16px;
}

#externalloginmiddleleftlookup h1 {
    font-size: 24px;
    margin-left: 40px;
}

.middlecc {
    margin-top: 2px
}

#forgotpasswordccmonthyearfields {
    display: block;
    clear: both;
    margin-left: 40px;
    margin-top: 10px;
    font-size: 14px;
    height: 30px;
}

#forgotpasswordccmonthyearfields p {
    display: inline;
    float: left;
    font-size: 15px;
    margin-right: 5px;
}

#forgotpasswordccmonthyearfields select {
    font-size: 15px
}

#externalloginusername {
    width: 280px;
    margin-left: 50px;
    margin-top: 20px;
    display: block;
    clear: both;
    height: 50px;
}

#externalloginusername input {
    display: inline;
    float: right;
    margin-left: 10px;
    width: 200px;
    font-size: 15px;
}

#externalloginusername p {
    display: inline;
    float: left;
    margin-top: 3px;
}

#externalloginpassword {
    width: 280px;
    margin-left: 50px;
    display: block;
    clear: both;
    height: 40px;
}

#externalloginpassword input {
    display: inline;
    float: right;
    margin-left: 10px;
    width: 200px;
    font-size: 15px;
}

#externalloginpassword p {
    display: inline;
    float: left;
    margin-top: 3px;
}

#externalloginrememberme {
    display: block;
    clear: both;
    height: 30px;
    width: 140px;
    margin-left: 122px;
}

#externalloginrememberme p {
    display: inline;
    float: left;
    margin-top: 1px;
    margin-left: 3px;
}

#externalloginrememberme input {
    display: inline;
    float: left;
}

#password {
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 14px !important;
}

#externallogoutpage {
    width: 800px
}

#externallogoutpageadarea {
    width: 800px;
    height: 600px;
    display: block;
    clear: both;
    margin-top: 30px;
}

#externallogouttext {
    font-weight: bold;
    color: #db272a;
    font-size: 14px;
}

#externallogoutusername {
    width: 300px;
    height: 40px;
    margin-top: 20px;
}

#externallogoutusername p {
    display: inline;
    float: left;
    margin-top: 3px;
    font-size: 14px;
}

#externallogoutusername input {
    display: inline;
    float: right;
    width: 200px;
    font-size: 15px;
}

#externallogoutpassword {
    width: 300px;
    height: 50px;
}

#externallogoutpassword p {
    display: inline;
    float: left;
    margin-top: 3px;
    font-size: 14px;
}

#externallogoutpassword input {
    display: inline;
    float: right;
    width: 200px;
    font-size: 15px;
}

#externallogoutforgotlink {
    width: 300px;
    text-align: right;
}

#externallogoutthirdline {
    width: 300px;
    height: 40px;
    margin-left: 0px;
}

#externallogoutrememberme {
    width: 145px;
    display: inline;
    float: right;
    height: 30px;
}

#externallogoutloginbtn {
    display: inline;
    float: right;
    height: 30px;
}

#externallogoutrememberme input {
    display: inline;
    float: left;
}

#externallogoutrememberme p {
    display: inline;
    float: left;
    margin-top: 2px;
    margin-left: 2px;
}

#externallogin .login-recaptcha {
    margin: 10px 0 20px 125px;
}

#externallogin .login-recaptcha.invalid iframe,
#externallogoutpage .login-recaptcha.invalid iframe {
    border: 2px solid red;
}

#externallogoutpage .login-recaptcha {
    margin: 0px 0 20px 95px
}

.forgotpasswordlookuptext {
    margin-left: 40px;
    margin-top: 10px;
}
/* dating-tips */
div.dating-tips {
    padding: 20px;
}

div.dating-tips h3 {
    font-size: 16px;
    font-weight: bold;
    color: #0070c0;
    margin: 30px 0 15px 0;
    text-decoration: underline;
}

div.dating-tips ul,
div.dating-tips > div,
div.dating-tips > ul > li {
    margin-bottom: 15px;
}

div.dating-tips a {
    text-decoration: underline;
}

div.dating-tips li > span {
    margin: 15px 0;
    display: block;
    text-align: center;
}

div.dating-tips p {
    font-style: italic;
    padding-top: 15px;
}
/* dating-tips end*/

.legal h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* privacy policy */
div.privacyPolicy h1 {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
}

div.privacyPolicy h1.center {
    text-align: center;
}

div.privacyPolicy h2 {
    font-size: 16px;
    font-weight: bold;
    margin: .75em 0;
    text-align: left;
}

div.privacyPolicy h3 {
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    margin: .75em 0;
    text-align: left;
}

div.privacyPolicy ol li,
div.privacyPolicy ul li {
    margin-bottom: 5px;
}

div.privacyPolicy .section-break {
    margin: 20px 0 20px 0;
}

div.privacyPolicy .section-break.center {
    text-align: center;
}

div.privacyPolicy .section-break span {
    margin: 0 5px 0 5px;
}


div.toc .toc-item {
    display: block;
    margin: 0;
    padding: 0;
}

div.toc .toc-number {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 10%;
    text-align: right;
    max-width: 25px;
}

div.toc .toc-link {
    display: inline-block;
    margin: 0;
    padding: 0;
    text-align: left;
    vertical-align: top;
    padding: 0 0 0 10px;
    width: 90%;
}

div.toc .toc-link a {
    display: inline;
}

div.privacyPolicy div.text {
    margin-bottom: 10px;
}

div.privacyPolicy .privacy-detail h2 span {
    margin-right: 10px;
}

span.emphasis {
    text-decoration: underline;
}

span.emphasis2 {
    font-style: italic;
}

/* cookie policy */
div.cookiePolicy h1 {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
}

div.cookiePolicy h2 {
    font-size: 16px;
    font-weight: bold;
    margin: .75em 0;
    text-align: left;
}

div.cookiePolicy h3 {
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    margin: .75em 0;
    text-align: left;
}

div.cookiePolicy div {
    margin-bottom: 10px;
}

div.cookiePolicy .cookie-policy-table {
    display: table;
    width: 100%;
    margin: 10px 0 10px 0;
    border-collapse: collapse;
}

div.cookiePolicy .cookie-policy-row {
    display: table-row;
}

div.cookiePolicy .cookie-policy-table-heading {
    background-color: #EEE;
    display: table-header-group;
    color: #000000;
    font-weight: bold;
}

div.cookiePolicy .cookie-policy-cell,
div.cookiePolicy .cookie-policy-heading-cell {
    border: 1px solid #999999;
    display: table-cell;
    padding: 3px 10px;
}

div.cookiePolicy .cookie-policy-heading {
    background-color: #EEE;
    display: table-header-group;
    font-weight: bold;
}

div.cookiePolicy .cookie-policy-table-body {
    display: table-row-group;
}

div.cookiePolicy .cookie-policy-cell:first-of-type {
    color: #000000;
    font-weight: bold;
}

/* terms and conditions */
div.termsAndConditions h1 {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
}

div.termsAndConditions p {
    margin-bottom: 10px;
}

div.termsAndConditions .red-text {
    color: red;
}

div.termsAndConditions {
    word-break: break-word;
}

div.termsAndConditions a {
    word-break: break-all;
}

@media print {
    #header,
    #columnrightexternal,
    #externallinks,
    #footer {
        display: none;
    }

    #columnleftexternal {
        display: block;
        width: 80% !important;
        font-size: 14px;
    }

    div.cookiePolicy,
    div.privacyPolicy,
    div.termsAndConditions {
        display: block;
        width: auto !important;
    }

    div.cookiePolicy h1,
    div.privacyPolicy h1,
    div.termsAndConditions h1 {
        font-size: 30px; /* desktop seems to be printing very small fonts */
    }

    div.cookiePolicy h2,
    div.privacyPolicy h2,
    div.termsAndConditions h2,
    div.cookiePolicy h3,
    div.privacyPolicy h3,
    div.termsAndConditions h3 {
        font-size: 24px; /* desktop seems to be printing very small fonts */
    }

    a, li, p {
        font-size: 21px; /* desktop seems to be printing very small fonts */
    }

    div.cookiePolicy div.text,
    div.privacyPolicy div.text,
    div.termsAndConditions div.text,
    div.cookiePolicy p,
    div.privacyPolicy p,
    div.termsAndConditions p {
        display: block;
        page-break-inside: avoid;
        font-size: 21px; /* desktop seems to be printing very small fonts */
    }

    .page-break {
        display: block;
        page-break-before: always;
    }
}

.security .commitment-section h1.center {
    /* Ignore the center class */
    text-align: left;
}

.security .commitment-section h2 {
    text-align: left;
    font-weight: bold;
    margin-top: 10px;
}

.results.gallery .no-results {
    margin: 50px auto 50px auto;
    text-align: center;
}

.results.gallery .modern-profile-card {
    display: inline-block;
    width: 188px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 0;
    margin: 16px;
}

.results.gallery .modern-profile-card.clickable {
    cursor: pointer;
}

.results.gallery .modern-profile-card img {
    width: 188px;
    height: 188px;
    z-index: 1;
    min-height: 188px;
}

.results.gallery .modern-profile-card img:nth-child(4n+1) {
    margin-left: 0;
}

.results.gallery .modern-profile-card .modern-profile-info {
    z-index: 2;
    margin: 0;
    margin-top: -50px;
    font-size: 16px;
    position: relative;
    height: 46px;
    color: #fff;
}

.results.gallery .modern-profile-card .modern-profile-info > :first-child {
    font-weight: 600;
}

.results.gallery .modern-profile-card .modern-profile-info.photo-caption-gradient {
    background: -webkit-linear-gradient(top, rgba(0,0,0,0), rgba(0,0,0,.75)); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(top, rgba(0,0,0,0), rgba(0, 0, 0,.75)); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(top, rgba(0,0,0,0), rgba(0,0,0,.75)); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.75)); /* Standard syntax (must be last) */
    color: #fff;
    text-align: left;
    padding: 3px 6px;
}

.results.gallery .modern-profile-card .modern-profile-info .modern-photo-count {
    position: absolute;
    right: 0;
    width: 34px;
    color: #fff;
    bottom: 0;
    text-align: center;
    height: 30px;
    padding-top: 5px;
}


.results.gallery .modern-profile-card .modern-profile-info .modern-photo-count i {
    font-family: 'PeopleMediaFont';
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    line-height: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.results.gallery .modern-profile-card .modern-profile-info .modern-photo-count i.bold {
    font-family: 'PeopleMediaFontBold'
}

.results.gallery .modern-profile-card .modern-profile-info .modern-photo-count i:before {
    display: inline-block;
    text-align: center;
    position: relative;
}

.results.gallery .modern-profile-card .modern-profile-info .modern-photo-count i.polaroid:before {
    content: "6"
}

.results.gallery .modern-profile-card .modern-profile-info .modern-photo-count i.polaroid.bold {
    position: absolute;
    right: 3px;
    bottom: 17px;
    font-size: 25px;
}

.results.gallery .modern-profile-card .modern-profile-info .modern-photo-count[data-photo-count]:after {
    font-size: 12px;
    content: attr(data-photo-count);
}

.results.gallery .modern-profile-card .modern-profile-info .modern-photo-count[data-photo-count^="0"],
.results.gallery .modern-profile-card .modern-profile-info .modern-photo-count[data-photo-count^="1"] {
    display: none;
}
